@font-face {
  font-family: 'ews';
  src:  url('fonts/ews.eot?av1yoe');
  src:  url('fonts/ews.eot?av1yoe#iefix') format('embedded-opentype'),
    url('fonts/ews.ttf?av1yoe') format('truetype'),
    url('fonts/ews.woff?av1yoe') format('woff'),
    url('fonts/ews.svg?av1yoe#ews') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="i-"], [class*=" i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ews' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-angle-down:before {
  content: "\e900";
}
.i-angle-down-f:before {
  content: "\e901";
}
.i-angle-left:before {
  content: "\e902";
}
.i-angle-left-f:before {
  content: "\e903";
}
.i-angle-right:before {
  content: "\e904";
}
.i-angle-right-f:before {
  content: "\e905";
}
.i-angle-up:before {
  content: "\e906";
}
.i-angle-up-f:before {
  content: "\e907";
}
.i-call:before {
  content: "\e908";
}
.i-category:before {
  content: "\e909";
}
.i-edit:before {
  content: "\e90a";
}
.i-email:before {
  content: "\e90b";
}
.i-facebook:before {
  content: "\e90c";
}
.i-heart:before {
  content: "\e90d";
}
.i-instagram:before {
  content: "\e90e";
}
.i-linkedin:before {
  content: "\e90f";
}
.i-minus:before {
  content: "\e910";
}
.i-plus:before {
  content: "\e911";
}
.i-searc:before {
  content: "\e912";
}
.i-send:before {
  content: "\e913";
}
.i-tag:before {
  content: "\e914";
}
.i-twitter:before {
  content: "\e915";
}
.i-user:before {
  content: "\e916";
}
.i-whatsapp:before {
  content: "\e917";
}
.i-youtube:before {
  content: "\e918";
}
.i-image:before {
  content: "\e919";
}
.i-cart:before {
  content: "\e93a";
}
.i-attachment:before {
  content: "\e9cd";
}
.i-star-empty:before {
  content: "\e9d7";
}
.i-star-half:before {
  content: "\e9d8";
}
.i-star-full:before {
  content: "\e9d9";
}
.i-sort-amount-asc:before {
  content: "\ea4c";
}
.i-sort-amount-desc:before {
  content: "\ea4d";
}
.i-filter:before {
  content: "\ea5b";
}
.i-xing2:before {
  content: "\ead4";
}
.i-file-pdf:before {
  content: "\eadf";
}
