

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&family=Quicksand:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus{outline: none;}

.block {
  display: block;
}

.defaulpad {
  padding: 80px 0px;
}

body {
  font-size: 14px;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
  color: rgba(0, 0, 0, 0.7);
}

p {
  font-size: 18px;
  margin: 10px 0px;
  line-height: 1.3;
  font-family: 'Barlow', sans-serif;
}

.mtop20 {
  margin-top: 20px !important;
}
.pdtb-100{
  padding: 100px 10px !important;
  font-size: 18px;
}
.pdtb-100 h1{
font-size: 128px !important;
}
.help-error{
  text-align: left;
    width: 100%;
    display: block;
    margin-top: 8px;
    padding-left: 7px;
    color: red;
}
.paddinTB40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

input:hover,
input:focus {
  stroke: 0;
  outline: 0;
  border: 0;
}

input[type=submit],
a {
  transition: all 300ms ease-in;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
border: none;
box-shadow: 0 0 0px 1000px rgba(41, 36, 36, 0) inset;
transition: background-color 5000s ease-in-out 0s;
}

.trnsintion {
  transition: all 400ms ease-in-out;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h3 {
  font-family: 'Quicksand', sans-serif;
  font-weight:bold;
  color: #000;
}

h3 {
  font-size: 20px;
  font-weight: 900;
}

h1 {
  font-size: 35px;

}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.link {
  min-width: 150px;
  text-align: center;
  padding:15px;
  color: #fff;
  font-size: 18px;

  display: inline-block;
  font-family: 'Poppins', sans-serif;
  border-radius: 25px;


}


.banner .link{   background: transparent;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  --webkit-backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.10);
  margin-top: 20px;
}
.banner .link:hover{ background-color: rgba(38, 223, 253, 1);   }
.responce-box{
  background: #1d9b32;
  padding: 50px;
  max-width: 401px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
}
.responce-box h3{
  margin-bottom: 0;
  color: #fff;
  font-size: 30px;
}
.responce-box p{
  color: #fff !important;
  font-size: 16px !important;
  margin-bottom: 15px;
}
.responce-box .link{
  padding: 15px 30px;
  background-color: #084281;
  min-width: 173px;
  font-weight: 300;
  font-size: 14px;
  text-transform: uppercase;
}

.fill-blank {
  border: 1px solid #707070;
  color: #000;
  margin-left: 20px;
}

.fill-yellow {
  color: #fff;
  background: #ffb600;
  box-shadow: 0px 21px 28px rgba(255, 230, 146, 0.63);
}

.fill-blue {
  color: #fff;
  background: #5050ca;
  box-shadow: 0px 21px 28px rgba(146, 157, 255, 0.51);
}

.header {
  position: fixed;
  width: 100%;
  
  left: 0;
  z-index: 100;
  background: transparent;
  background-color: rgba(78, 98, 160, 0.1);
  transition: all 400ms ease-in-out;
}

.header.active{
  background: transparent;
  backdrop-filter: blur(15px);
  --webkit-backdrop-filter: blur(15px);
  background-color: rgba(8, 29, 91, 0.6);
  top: 0px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: calc(100% - 30px);
  padding: 0px 15px;
}

.header .container {
  display: flex;

  height: 80px;
  align-items: center;
}

.menubutton {
height: 27px;
  z-index: 4;
  position: relative;
  background:transparent;
  text-align: center;
border: 0px;
vertical-align: middle;
display: none;
}

.menubutton span {
  display: block;
  width: 21px;
  height: 2px;
  position: relative;
  transition: all .3s ease-in-out;
  background-color: rgba(255, 255, 255, 1);
  margin: 5px auto;
}

.menubutton.opened span:first-child {
  transform: rotate(45deg);}

  .menubutton.opened span:nth-child(3) {
    transform: rotate(-45deg);
}
.menubutton.opened span {
  margin: -2px auto auto;
}

.menubutton.opened span:nth-child(2) {
  visibility: hidden;
  height: 0;
}

.brand{flex-grow: 1;}
.brand img {
  height: 60px;
  vertical-align: middle;
}


.navebar,
.nav {
  display: flex;
  list-style: none;
  align-items: center;
}
.nav li {
  padding: 0px 10px;
  color: #fff;
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
  cursor: default;
}

.nav li:hover, .nav li a:hover, .nav a.activelink{ color: #42C2F3;}

.nav li:nth-child(1) {
  padding-left: 0px;
}
.nav a {
  display: block;
  color: #fff;
}
.nav i{vertical-align: middle;}

.navebar a.selected,
.navebar a:hover {
  opacity: 1;
}

.submenu{  overflow: visible; } 
.submenu:hover{overflow: visible; }
.megamenu {
  transition: all 300ms ease-in-out;
  margin-top: 3vh;
  position:absolute;
visibility:hidden;
width:250px;
left: -75px;

padding-top: 32px;
opacity: 0;
}
.color{animation:colordot 5s linear infinite; transition-delay: 2.5s; }
@keyframes colordot{

}

.submenu:hover .megamenu{margin-top: 0vh; visibility:visible; opacity: 1;}
.backdrop{ position: relative;
  padding: 20px 0px; background: transparent;
  backdrop-filter: blur(11px);
  --webkit-backdrop-filter: blur(11px);
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
   }
   .backdrop::after{ 	border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ced2de; position: absolute;
  left: 50%; margin-left: -15px; top: -10px; content: '';
  }


.megamenu a{line-height: 1.5;color: #083099 !important; 
   padding: 10px 30px; border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top:1px solid rgba(255, 255, 255, 0.5);}
    .megamenu a:nth-child(1){border-top: 0px;}
    .megamenu a:nth-last-child(1){border-bottom: 0px;}

.megamenu h3{ margin-bottom: 0px; margin-top: 5px;  color: #0000; font-size: 16px; 
   padding: 5px 0px; padding-bottom: 0px;} 
   .megamenu h3 a{color: #EF008B; margin-left: 0px; padding-left: 0px; border-left: 0px;}
.megamenu a:hover, .megamenu a.activeSubmenu{ background-color: #42C2F3}

.banner, .contentpart {
min-height: calc(100vh - 82px);
  background: rgb(8,22,67);
background: linear-gradient(155deg, rgba(8,22,67,1) 0%, rgba(8,48,153,1) 100%);

  display: flex;
  align-items: center;
  position: relative;
}
.contentpart { width: 100%; min-height:calc(100vh - 82px);
  background: transparent;
  /* Note: currently only Safari supports backdrop-filter */
  backdrop-filter: blur(50px);
  --webkit-backdrop-filter: blur(50px);
  background-color: rgba(8,22,67, 0.50);
  
}

.bannerContent{position: relative; z-index: 1;}

.bgEfect{position: absolute; height: 100%; width: 100%; 

  background-image: url('./img/gradiantBg.svg'); background-size: cover; background-position: center center;}
.banner .container::after, .banner .container::before, .bannerimage::after, .bannerimage::before{position: absolute; content: ''; 
  border-radius: 100%; }

  .banner .container::after, .bannerimage::after {
    height: 30px;
    width: 30px;
    background-color: #FE8A17;
    left: 50%;
    top: 0%;
    animation:circle 30s linear infinite;
  }
  
  .banner .container::before, .bannerimage::before {
    height: 15px;
    width: 15px;
    background-color: #D0D98B;
    left: 10%;
    bottom: 0%;
    animation:circleg 30s linear infinite;
   bottom: 5%;
  }
  .bannerimage::after, .bannerimage::before{ animation-duration: 25s; animation-delay: 1s;}
  .bannerimage::before{ height: 10px;
    width: 10px;
    background-color: #D0D98B;}
    .bannerimage::after{  height: 20px;
      width: 20px;
      background-color: #26DFFD;}

  @keyframes circle{
  0%{  left: 50%;
    top: 0%;}
   25%{  left: 5%;
    top:90%;}
   50%{  left: 10%;
    top:0%; }
   75%{  left: 40%;
    top:90%; }
    100%{  left: 50%;
      top: 0%;}
  }
  @keyframes circleg{
    0%{  left: 10%;
      bottom: 0%;}
     25%{  left:30%;
      bottom:100%;}
     50%{  left: 60%;
      bottom:0%;}
     75%{  left: 10%;
      bottom:100%; }
      100%{  left: 10%;
        bottom: 0%;}
    }

    .bannerimage{position: relative;}
    .bannerimage img{ max-width: 600px;
      width: 95%;
      min-width: 320px;}

.banner .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}


.banner h1 {
  font-size: clamp(27px, 3.5vw, 54px);
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #fff;
  line-height: 1.4;
  background: rgb(228,251,255);
  background: radial-gradient(circle, rgba(228,251,255,1) 42%, rgba(13,190,218,1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; animation: anim 5s linear infinite;
}


.contet{padding-left: 15px; padding-top: 15px; position: relative; }

.banner p {
  font-size:18px;

  color: #fff;
}
.explore{ position: relative; z-index: 5; background-color: #0bb5cf;}
.explore .container{height: 82px; align-items: flex-end;}


.explore .link{display: block; background-color:#0DBEDA; line-height: 60px; padding: 0px 20px; border-radius: 0px;}
.explore .heading{ align-self: center;}
.explore a{
  display: inline-grid;
}
.explore h2{font-size: 25px;
  text-align:left;
  color: #fff;

width: auto;
display: inline;

}

.explore a div{line-height: 0px;}
.explore a img{width: 100%;}

.imtab{ margin-left: 20px;
  border-radius: 15px;
  transition: all 300ms ease-out;
  box-shadow: -5px -5px 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  transform: perspective(000px) rotateY(
0deg
);
  position: relative;
  top: -28px;}


  .imtab:hover{   transform: perspective(400px) rotateY(
    20deg
    );}
/*pagenotfound*/
.page-not-found{
  margin-top: 40px;
}
/* section */
.sectionTitle{ padding-left: 15px;  font-size: 33px; color: #08236f; position: relative;
  background: rgb(236,56,80);
  background: linear-gradient(45deg, rgba(236,56,80,1) 0%, rgba(254,138,23,1) 100%);
width: auto;
display: inline;
-webkit-background-clip: text;
font-family: 'Poppins', sans-serif;
    background-clip: text;
    -webkit-text-fill-color: transparent;

}

.sectionTitle::after, .sectionTitle::before{ position: absolute; content: ''; background-color: rgba(236,56,80,1) ; }
.sectionTitle::after {left: 0px; top: -10px; height: 20px; width: 5px;}
.sectionTitle::before {left: 0px; top: -10px; height: 5px; width: 20px;}

.iheading .sectionTitle{width: auto;
  display: inline-block; }
.sectionTitle.white{color: #b4c6f8;}
.section{ padding:70px 0px;}
.section-130{ padding:130px 0px;}

.headingwithLine{margin-bottom: 30px;}
.headingwithLine h2{  padding-right: 20px;}
.headingwithLine .line{background-color: rgba(112, 112, 112, 0.10);
  height: 1px;
  margin-right: 50px;
  position: relative;}
  .headingwithLine .line.white{background-color: rgba(255, 255, 255, 0.30);}

.headingwithLine .line::after, .headingwithLine .line::before{ position: absolute; content: ''; }
.headingwithLine .line::after{right: -50px;
  margin-top: -2px;
  width: 35px;
  height: 5px;
  background: #fe8a17;;}

  .headingwithLine .line::before{    right: -10px;
    margin-top: -2px;
    width: 5px;
    height: 5px;
    background: #d0d98b;
}
  

/* homeAboutMe */


.image img{
  width: 100%;
}
.contents p{ text-align: justify; line-height: 1.4; }
.readmoreBar{background-color:rgba(0, 75, 255, 0.05); margin-top: 20px; text-align: right; border-radius: 20px;}
.readmoreBar a, .submit{background: #0dbeda;}
.readmoreBar a:hover, .submit:hover{background-color:#FE8A17 ;}

/* digitalTransformation */

.digitalTransformation{
background-color:#081E5B ;
background-image:url('./img/circleBg.svg');
background-position: center center; background-size: 100% auto;
}

.hPC{
  font-size:35px;
  font-weight: 300;
  color: #066184;
  line-height: 1.5;
}

.hPC4{  font-size: 24px;  color: #066184;  line-height: 1.5; margin: 15px 0px; font-weight: 700;}

.dt2{padding: 60px 0px; }

.dt25{padding: 25px 0px;}
.dtimg{width: 100%; border-radius:15px;}

.dt iframe, video{margin-bottom: 30px;     border-radius: 20px;}

.dt .link{ padding: 15px 30px;
  background-color: #084281;
  min-width: 300px;
  font-weight: 300;}

.dt .link:hover{ background-color: #43c1f2;}
.dt p{
  text-align: left;
  margin-bottom: 20px;
}
.dStyle p, .dStyle ul li{   font-family: 'Barlow', sans-serif;
  font-weight: 300;
  font-size: 20px;
  color: #384057;
}
.contents p {
  font-family: 'Barlow', sans-serif;
  line-height: 1.4;
  font-size: 18px;
}

p 
  .dStyle h2, .dStyle h3, .dStyle h4 {font-family: 'Quicksand', sans-serif; font-weight: 300;}
  .dStyle h2 span, .dStyle h3 span, .dStyle h4 span, p span{font-family: 'Quicksand', sans-serif; font-weight: 700;   
      background: rgb(236,56,80);
    background: linear-gradient(45deg , rgba(236,56,80,1) 0%, rgba(254,138,23,1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent; }

    .dStyle .form{margin-top: 20px;}
    
    .dStyle ul li::marker {  color: rgb(236,56,80); }
.ulList{
  font-size: 14px;
  margin: 10px 0px;
  line-height: 1.2;
  font-family: 'Poppins', sans-serif; 
  color: rgba(0, 0, 0, 0.6);
  margin-left: 20px;
}
.ulList li{margin: 15px 0px; line-height: 1.4;}

.agree {text-align: left; padding: 15px 0px 0px 0px}
.agree input{ width: auto !important; margin-right: 10px; margin-top: 0px !important; }

.digitalTransformation .flex{
  justify-content:space-between;
  align-items: center;
}
.circles{
background: #0a256e;
border-radius: 100%;
overflow: hidden;
position: relative;
line-height: 0;
margin: 0px 10px;
}

.circles img{ width:100%; max-width: 210px; min-width: 100px; opacity: 0.58; transition: all 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
   transform: scale(1.0);}
   a .circles:hover img{ transform: scale(1.5);}
.circles div{ position: absolute; width: 100%; height: 100%;  display: flex;
   align-items: center; 
   left: 0;
   top: 0;
   letter-spacing: 1px;
   font-size:clamp(16px, 2vw, 20px);
   color: #fff;
   justify-content: center;   font-family: 'Poppins', sans-serif; font-weight: 600; }

   .circles div span{position: relative; z-index: 2;}

   .circles div::after{ position: absolute; height: 34px; transform: scale(1.0); transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
     width: 34px; background: #fe8a17; border-radius: 100%; content: ''; }
   a .circles:hover div::after{ transform: scale(5.0); opacity: 0.9;}
.somthing-me h3 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.circles.center div{ background: #0a256e;  font-size: 44px; }
.circles.center div b{display: block; font-size: 17px; margin-top: 15px;}
.circles.center div::after{display: none;}
.circles.center img{opacity: 0;}

.industry{ display: flex; align-items: center;   }

.iheading{ min-width:30vw; }
.iheading{text-align: center;}

.icontent{position: relative; overflow: hidden;}
.icontent img{width: 100%;  transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); display: block; 
  transform: scale(1.0);}
.icontent:hover img{  transform: scale(1.5);}

.icontent div{ width: 100%; background: rgba(255, 255, 255, 0.44);
   height: 100%; position: absolute; left: 0px; top: 0px; display: flex;
    justify-content: center; align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 27px;
    color: #081e5b;
  }
  .icontent div span{position: relative; z-index: 2;}
  .icontent div::after{position: absolute; height: 62px; transform: scale(1.0); transition: all 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275); width: 62px; background: #26dffd; border-radius: 100%; content: '';}
  .icontent:hover div::after{transform: scale(4.0); opacity: 0.8;   background: rgb(236,56,80);
    background: linear-gradient(45deg, rgba(236,56,80,0.9) 0%, rgba(254,138,23,0.9) 100%);}
  .icontent:hover div{color: #fff;}

  /* new */
.blueBg{
  background: rgb(8,22,67);
background: linear-gradient(155deg, rgba(8,22,67,1) 0%, rgba(8,48,153,1) 100%);}
  
.offWhite{background: #ebeff0;}

.clogos{display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 20px;
  margin: 10px;
  margin-top: 0px;
  border-radius: 10px;
  height: 100%;}

  .offWhite .row>* {margin: 10px 0px;}

  .clogos img{max-height: 70px; max-width: 100%;  }
.about p{text-align: justify !important;}
  .team{ padding:15px; border-radius: 2px; }
  .team img{width: 90%; max-width: 200px; border-radius: 100%; margin-bottom: 15px; }
  .team h2{font-size: 24px; color: #000;}
  .team p{ line-height: 1.5; font-size: 16px !important; text-align: center !important; }
  h3{margin-bottom: 20px;}

/* footer */
footer {
  padding: 80px 0px 0px 0px;
  background-color: #133593;
 
}
.Flogo img{ height: 60px; margin-bottom: 10px;}
footer p {

  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  color: #fff;
}

footer .fill-blank {
  margin-left: 0;
}

footer h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 25px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;
}


.footerBT {
  margin: 25px 0px;
  margin-bottom: 50px;
}
.social-icon a{display: inline-block; margin: 5px;}
.social-icon a i{
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.36);
  border-radius: 100%;
  color: #fff; 
  display: flex;
justify-content: center;
align-items: center;
font-size: 22px;
transition: all 300ms ease-in;
}
footer a{ display: inline-block;
  color: #efefef; font-size: 15px; }
  footer a:hover{color: #26DFFD;}
  .Flink a{ padding:2px 10px; display: inline-block; border-right: 1px solid rgba(255, 255, 255, 0.1);}

  .Flink a:nth-child(1){padding-left: 0px;}
  
  .Flink a:nth-last-child(1){padding-right: 0px; border-right: 0px;}

.social-icon a:hover i{background-color: #26DFFD;}

.social-icon a img {
  transition: all 300ms ease-in;
}

.copyright{padding:25px 0px; text-align: center; font-size: 15px; color: #d6d5dc; border-top: 1px solid rgba(255, 255, 255, 0.1); margin-top: 15px;}

.staytune{display: inline-block;
  width: fit-content; background: rgb(236,56,80);
  background: linear-gradient(
45deg
, rgba(236,56,80,1) 0%, rgba(254,138,23,1) 100%);
  padding: 5px 20px; padding-bottom: 10px; color: #fff !important; border-radius: 3px;}

/* page */

.pagebanner{padding-top:150px; padding-bottom: 50px;
   background-color: #08194d; 
  background-image:url('./img/pageBanner.svg'); background-repeat: no-repeat; 
  background-position:98% 80%; background-size: auto 60%; }


  .pagebanner2{ position: relative; line-height: 0;}
  .pagebanner2 img{width: 100%; height:400px; line-height: 1; object-fit: cover; object-position:center center}
  .b-overlay{height:100%; width: 100%;

    line-height: 1; display: flex; padding-bottom: 55px; align-items:flex-end; position: absolute; left:0; top: 0; background-color: #08194d48;} 
 
  .page{background-color: #e3f2f9;}
.pageContent{
padding: 30px 40px; position: relative;
top: -50px; background-color: #fff; border-radius: 20px;
}

.pd{padding: 0px !important;}

.Contactde{align-items:flex-start;}

.pdt-35{padding-top: 35px;}

.Contactde i{height: 55px; min-width: 55px;
  display: flex; text-align: center; align-items: center; justify-content: center; font-size: 25px;
  margin-right: 14px; color: #08236f; 
  background-color: rgba(0, 75, 255, 0.1); border-radius: 100%;}
  .Contactde h2, .form h2{ margin-bottom: 3px; color: #08236f;}
.Contactde p{margin:0px;}

/* .dStyle .form{border-top: 1px solid rgba(0, 0, 0, 0.1);} */
.form h2{margin-top: 25px;}
.conatct-page .form h2{margin-top: 0px;}
.conatct-page .form{margin-top: 0px;}
.form input, .form button, .form textarea{ width: 100%; display: block; margin-top: 10px;}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #384057;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #384057;
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #384057;
}

.form input, .form textarea, .form select{
  border-bottom: 1px solid rgb(8 76 101 / 20%);
  padding: 10px 10px;
  font-size: 18px;
  background-color: transparent;
  font-family: 'Barlow', sans-serif;  
  font-weight: 300;
  color: #0c4d7a;
}
.form select{width: 100%; margin-top: 10px;}
.form input:hover,
 .form input:focus,
 .form select:focus,
  .form textarea:hover,
   .form textarea:focus{
 border-color: rgb(16, 146, 167);
  outline: none;
}
.form label span{color: red;}

.form label{   font-family: 'Barlow', sans-serif;  
  font-weight: 300; font-size: 16px; color: #384057; line-height: 1.4;}
/* blog */
.cotegory{ padding: 30px 0px; height: 100%;}

.cotegory h3{
  font-size: 22px;
  color: #fe8a17;
  padding: 0px 30px;
  padding-bottom: 15px ;
  margin-bottom: 3px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .cotegory.right a{font-size: 16px;
   padding: 15px 30px;
  position: relative;
    color: #536570;
    display: block;
    border-top:1px solid rgba(0, 0, 0, 0.08); 
    }
 
    .cotegory.right a::before{  width: 16px;  content: ''; border-radius: 100%;
      height: 16px; display: inline-block; margin-right: 6px;  background: #536570;
      vertical-align: middle; transition: all 300ms ease-out;
      box-shadow: 0px 0px 1px 1px rgba(112, 112, 112, 0.25);
      border: 4px solid #fff; }

      .cotegory.right a.active, .cotegory a:hover{ color:#FE8A17;}
      .cotegory.right a.active::before, .cotegory a:hover::before{ background:#FE8A17;}

      /* homeNewThumv */
      .homeNewThumv{height: 100%;}
      .homeNewThumv>*{line-height: 0px;}
      .medislider .react-multiple-carousel__arrow {
        background: rgba(2, 15, 71, 0.527);
    }
    .medislider .react-multiple-carousel__arrow:hover {
        background: #FE8A17;;
    }

.thumbnails{padding: 15px 30px 15px 0px;}
.thumbnails.details{padding:0px; padding-right: 0px; }
.thumb{border-radius: 3px;
  height: 100%;
  background: #fff;
  border-radius: 10px; overflow: hidden; 
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
  }
  .homeNewThumv .thumb{background: #ffffff12;}

  .homeNewThumv .thumb{ box-shadow: unset; flex-direction: column;}
  .medislider li{padding:0px 10px}
  .thumb-img{height: 100%;}
  .homeNewThumv .thumb-img{width: 100%;}
  .thumb img{ height: 100%; width: 100%; min-width: 180px; object-fit: cover; }
  .homeNewThumv .thumb img{  width: 100%; min-width: unset; object-fit: cover; }
  .thumb h3{font-weight: bold;
    font-size: 18px;
 margin-top: 15px;
 padding: 0px 20px;
    text-align: left;
    color: #fe8a17; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;
   }
   .thumb p{font-size: 15px;
    text-align: left;
    color: #7887af; text-align: justify; padding: 0px 20px; }

 .homeNewThumv .thumb p{ color: #b6c4ec;}

    .readMore{text-align: right; }
    .readMore a{font-size: 14px; position: relative;
      background: rgba(0, 0, 0, 0.05); display: inline-block;
      color: #fe8a17; line-height: 28px; padding-left: 15px; margin-top: 10px; margin-bottom: 20px; }
      .readMore a span{z-index: 2; position: relative;}
      .readMore a::after{content: '+'; height: 28px; width: 28px; text-align: center; line-height: 28px; margin-left: 15px; display: inline-block; background-color: #fe8a17; color: #fff; z-index: 2; position: relative;}
      .readMore a::before{position: absolute; transition: all 300ms ease-out; height: 100%; width: 0%; content: ''; right: 0; top: 0; background-color: #E77A0F;}

      .readMore a:hover{ color: #fff;}
      .readMore a:hover::before{width: 100%;}

     
      .sectionTitle.back::before {
        left: -8px;
        top: 4px;     transform: rotate(-45deg);}

        .sectionTitle.back::after {
          left: 0px;
          top: 8px;     transform: rotate(-45deg);}

          .details h1{color: #fe8a17; line-height: 1.4;}
          .details img{ width: 100%; border-radius: 3px; margin-bottom: 5px;  margin-top: 15px;  }
          .details p{color: #7887af; line-height: 1.4;}
          .details p:nth-last-child(1){margin-bottom: 0px;}

          .blogInfo{ display: flex; justify-content: space-between; align-items: center;
            flex-wrap: wrap; padding: 15px 0px; margin-top: 15px; margin-bottom: 5px; border-top: 1px solid rgba(0, 0, 0, 0.1);
             border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
             .blogInfo div{line-height: 1.5;}
             .smallthumbnails{display: flex; padding: 15px 0px; overflow: hidden; 
              border-bottom: 1px solid rgba(0, 0, 0, 0.07);   border-top: 1px solid rgba(255, 255, 255, 0.5); }
             .smallthumbnails h3{padding: 0; font-size: 20px; overflow: hidden; border-bottom:unset;  white-space: nowrap; width: 100%;   text-overflow: ellipsis;}
               
             .smallthumbnails:nth-child(1){border-top:unset; padding-top: unset;}
             .smallthumbnails:nth-last-child(1){ border-bottom: unset; padding-bottom: unset  ;}
             .smallthumbnails img{max-width: 67px; max-height: 67px; margin-right: 15px; border-radius: 3px;}
             .smallthumbnails p{overflow: hidden; white-space: nowrap; margin: 5px 0px; font-size: 16px;    text-overflow: ellipsis; width: 100%;}
             .bound{overflow: hidden; flex-grow: 1;}

             .smallthumbnails .readMore a{ margin: 0px !important;}
            

             .tabss{ border-bottom: 1px solid rgba(0, 0, 0, 0.1); margin-bottom: 20px;}
             .tabss a.link{background-color: rgba(0, 0, 0, 0.10); color: #000; padding: 15px 30px; border-radius: 5px; margin-right: 10px; margin-bottom: 10px; }
             .tabss a.link.active, .tabss a.link:hover{background-color: rgba(13,190,218,1); color: #fff;}

             .efctBg{ background-image:url('./img/circleBgs.svg'), url('./img/productBg.svg'), url('./img/productBG2.svg'); 
              background-repeat: no-repeat; background-attachment:fixed, scroll, scroll; background-position:center 200%, right top, left bottom;
               background-size:40% auto, 25% auto, 25% auto;
        
               background-color: rgb(255 255 255 / 100%);
              }


/* max-width:1170px */
@media only screen and (max-width:1170px) {
  .brand img {
    height: 27px;
}
.nav li {
  font-size: 12px;
}
.searchbar input[type=text] {

  max-width: 133px;
}
}

/* max-width: 992px */
@media only screen and (max-width: 992px) {
  .brand img {
    height: 33px;
}

.header .container {
  height: 60px;
}
.header { top: 5px;}
.contents p {
font-size:12px;
  line-height: 1.2;
}

.banner {
  padding-top:0px;
}
.explore a img {
  width: 165px;
}
.explore .container {
  height: 44px;
}
.explore .link {
  line-height: 44px;
  padding: 0px 10px;
  font-size: 13px;
}
.explore h2 {
  font-size: 16px;
}
.bannerimage img {
  max-width: 420px;
}
.banner p {
  font-size: 18px;
}
.link {
  font-size: 16px;
}
.contet::after {
  height: 40px;
  left: -5px;
  top: -5px;
  width: 5px;
}
.contet::before {
  height: 5px;
  width: 40px;
  left: -5px;
  top: -5px;
}
.contet::before {
  height: 5px;
  width: 40px;
  left: -5px;
  top: -5px;
}
.sectionTitle {
  font-size: 27px;}

  .circles.center div {
    font-size: 30px;
}
.circles.center div b {
  font-size: 12px;
}
a .circles:hover div::after {
  transform: scale(3);
}
.circles div {letter-spacing: 1px;}
.section-130 {
  padding: 70px 0px;
}
.section {
  padding: 50px 0px;
}
.icontent div {
  font-size: 20px;
}
.icontent:hover div::after {
  transform: scale(3.0);
}
footer {
  padding: 50px 0px 0px 0px;
}
.social-icon{padding-top: 20px;}

.Flink a {
  padding: 6px 0px;
  display: block;
  padding-left: 10px !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 0px;
}
}

@media only screen and (max-width: 720px) {

  .digitalTransformation .flex {
    flex-direction: column;
}
.digitalTransformation {
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.circles {
  margin: 10px 0px;
}


}

@media only screen and (max-width: 768px) {
  .pageContent {
    padding: 20px 15px;
}

.hPC {
  font-size: 20px;
  margin: 15px 0px;
}
.pageContent {
 
  border-radius: 10px;
}
.sectionTitle {
  font-size: 20px;
}
  .pagebanner {
    padding-top: 94px;}
  .nav{position: fixed;
    transition: all 400ms ease-in;
    height: 100vh;
    display: block;
    z-index: -1;
    width: 250px;
    right: -280px;
    padding-top: 65px;
    padding-left: 20px;
    padding-right: 20px;
    top: 0;
    background: transparent;
    backdrop-filter: blur(12px);
    --webkit-backdrop-filter: blur(12px);
    background-color: rgba(3, 21, 77, 0.6);
    box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.2);     
  }
  .nav.opened{right: 0px; }

  .nav li{padding-left: 10px !important;}
  .nav li:hover{background-color: rgba(255, 255, 255, 0.1);}
    .navebar li a,  .navebar li {
      letter-spacing: inherit;
      font-size: 14px;
      padding: 0px 0px;
      padding: 4px 0px;
  }

  .megamenu {
    transition: all 300ms ease-in-out;
    margin-top: 0px;
    position: unset;
    visibility: visible;
    width: auto;
    padding-top: 5px;
    padding-left: 0px;
    opacity: 1;
}
.megamenu a{border: 0px; color: #fff !important;
   border-left: 1px solid rgba(255, 255, 255, 0.3); padding-left: 7px !important; line-height: 1.8;}
.backdrop::after{display: none;}
.backdrop {
  padding: 0px 0px;
  background: transparent;
  -webkit-backdrop-filter: unset;
  backdrop-filter: unset;
  --webkit-backdrop-filter: unset;
  box-shadow: unset;
  border-radius: 0px;
  background-color: transparent;
}

  .menubutton {
    display: block;
    margin-right: 10px;
}
  .navebar li{padding:5px 0px;}

  .aboutHome .contents p {
    margin-top: 0;
}
.aboutHome.aic{align-items:flex-start;}
  .banner .container {

    justify-content: space-around;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 70px;
    padding-bottom: 40px; 
}
.imtab {
  margin-left: 5px;
  margin-right: 5px;
}
.explore a img {
  width: 150px;
}
.explore .heading {
    display: none;
}
  .copyright {
    text-align: left;
}
  footer h3 {
    margin-bottom: 10px;
    margin-top: 25px;
}

  .container {
    width: calc(100% - 0px);
  }

  .navebar a {
    letter-spacing: inherit;
    font-size: 12px;
    padding: 0px 5px;
  }

  .headerRow {
    height: 50px;
  }

  h1 {
    font-size: 24px;
  }


  p {
    font-size: 14px;
  }


  footer .logo {
    height: 28px;
    margin-bottom: 13px;
    width: auto;
  }

}

@media (max-width: 575.98px){
  .thumbnails.details {
    padding: 20px;
}
.smallthumbnails {
  padding: 15px 20px;}
.cotegory{padding:20px 0px}
  .aboutHome .contents p {
    margin-top: 10px;
}
.explore .link {
  line-height: 44px;
  padding: 0px 10px;
  font-size: 12px;
}
.explore .container {
  justify-content: center;
}
.iheading {
  text-align: center;
  padding: 40px 0px;
}
.industry {
  display: flex;
  align-items: center;
  flex-direction: column;
}
}